<template>
  <header v-if="lang == 'en'">
    <p>Explore available airport opportunities&nbsp;below.</p>
  </header>
  <header v-else-if="lang == 'fr'">
    <p>Voyez ci-dessous les possibilités offertes à&nbsp;l’aéroport.</p>
  </header>

  <LoadingAnimation v-if="loading" />

  <div class="alert" v-else-if="error">
    <strong v-if="lang == 'fr'">Une erreur est survenue</strong>
    <strong v-else>There was an error</strong>
    <p>{{ error.message }}</p>
  </div>

  <div class="job-board" v-else>
    <SearchFilters @filter="filterJobs" @sort="sortJobs" :companies="allCompanyNames" :lang="lang" />

    <ul class="jobs" v-if="jobs.length > 0">
      <li v-for="job in paged(jobs, pageNumber)" v-bind:key="job.jobID._text">
        <JobPost :job="job" :lang="lang"></JobPost>
      </li>
    </ul>
    <div class="alert" v-else>
      <strong v-if="lang == 'fr'">Veuillez tenter une nouvelle recherche.</strong>
      <strong v-else>No results found</strong>
    </div>


    <PageSwitcher :lang="lang" :pageNumber="pageNumber" :jobCount="jobCount" :postsPerPage="postsPerPage" @changePage="changePage" />
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { xml2json } from 'xml-js';
import JobPost from '../components/JobPost.vue';
import PageSwitcher from '../components/PageSwitcher.vue';
import LoadingAnimation from '../components/LoadingAnimation.vue';
import SearchFilters from '../components/SearchFilters.vue';

export default {
  name: 'AppHome',
  components: {
  JobPost,
    PageSwitcher,
    LoadingAnimation,
    SearchFilters
  },
  props: ["language"],
  provide() {
    return {
      moment
    }
  },
  computed: {
    lang() {
      return this.language ? this.language : this.defaultLang
    },
    jobCount() {
      return this.jobs.length;
    }
  },
  created() {
    if (this.supportedLanguages.indexOf(this.lang) == -1) {
      this.error = {
        message: "Unsupported language"
      };
    }

    axios.defaults.baseURL = process.env.XML_BASE || 'https://pearsonworks.inksight.ca/';
    axios.get(`/latest.xml`).then(resp => {
      const xmlData = JSON.parse(xml2json(resp.data, { spaces: 2, compact: true }));
      this.loading = false;
      this.lastBuildDate = xmlData.source.lastBuildDate;
      this.jobs = xmlData.source.job.sort((a, b)=> {
        return b.pubDate._text - a.pubDate._text;
      });
      this.jobs.forEach((job) => {
        if(this.allCompanyNames.indexOf(job.employerName._cdata) == -1) {
          this.allCompanyNames.push(job.employerName._cdata);
        }
      })
      this.allCompanyNames.sort((a,b) => a.localeCompare(b));
      this.allJobs = this.jobs;
    }).catch(err => {
      this.loading = false;
      this.error = err
    })
  },
  data: function() {
    return {
      error: false,
      loading: true,
      defaultLang: 'en',
      supportedLanguages: ['en', 'fr'],
      lastBuildDate: null,
      pageNumber: 1,
      postsPerPage: 7,
      allCompanyNames: [],
      filters: {},
      allJobs: {},
      jobs: {}
    }
  },
  methods: {
    paged(object, page, postsPerPage) {
      if (!object.length || object.length == 0) return [];
      if (postsPerPage === undefined) {
        postsPerPage = this.postsPerPage;
      }
      return object.slice((page - 1) * postsPerPage, page * postsPerPage);
    },
    changePage(n) {
      this.pageNumber = n;
    },
    filterJobs(param, value) {
      if (param == 'clear') {
        this.filters = {
          keyword: this.filters.keyword
        }
      } else {
        this.filters[param] = value;
      }

      this.loading = true;
      this.pageNumber = 1;

      const searchTerm = this.filters.keyword;
      if (searchTerm && searchTerm.length > 1) {
        const searchRegex = new RegExp('('+searchTerm+')', 'i')
        this.jobs = [...this.allJobs].map((job) => {
          if (job.jobTitle._cdata.search(searchRegex) > -1) {
            job.weight = 2;
          }
          else if (job.employerName._cdata.search(searchRegex) > -1) {
            job.weight = 2;
          }
          else if (job.jobDescription._cdata.search(searchRegex) > -1) {
            job.weight = 1;
          }
          else {
            job.weight = 0;
          }
          return job;
        }).filter((job) => {
          return job.weight > 0;
        });
      } else {
        this.jobs = this.allJobs;
      }

      if (this.filters.date) {
        this.jobs = [...this.jobs].filter((job) => {
          const filterAfter = moment().subtract(this.filters.date, 'hours');
          return moment(job.pubDate._text, 'x').set('hour', 24).isAfter(filterAfter);
        })
      }

      if (this.filters.company) {
        this.jobs = [...this.jobs].filter((job) => {
          return job.employerName._cdata == this.filters.company
        })
      }

      if (this.filters.language) {
        this.jobs = [...this.jobs].filter((job) => {
          return job.language._cdata == this.filters.language;
        })
      }

      this.loading = false;
    },
    sortJobs(order) {
      switch (order) {
        case 'default':
          this.jobs.sort((a, b)=> {
            return b.pubDate._text - a.pubDate._text;
          });
          break;
        case 'relevance':
          if (this.filters.keyword) {
            this.jobs.sort((a, b)=> {
              return b.weight - a.weight;
            });
          }
          break;
        default:
      }
    }
  }
}
</script>

<style>
#job-search-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0 auto;
}

@media (min-width: 960px) {
  #job-search-app {
    max-width: 520px;
  }
}

#job-search-app header {
  font-size: 23px;
  text-align: center;
  color: #5f6a72;
  line-height: 1.4;
}

#job-search-app header h1 {
  margin-bottom: 0;
  font-size: 33px;
  font-weight: normal;
  color: #000;
}

#job-search-app header h1 + p {
  margin-top: 0.5em;
}

#job-search-app .jobs {
  margin: 0;
  padding: 16px 16px 1px;
  list-style-type: none;
  background: #eef4f9;
}

#job-search-app .alert {
  padding: 1em 40px;
  background: #eeeeee;
  text-align: center;
}

#job-search-app .alert p {
  margin-bottom: 0;
}

#job-search-app .button {
  display: inline-block;
  padding: 1em 3em;
  margin-top: 26px;
  background: #002662;
  border-radius: 1.5em;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  line-height: 1;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
</style>
