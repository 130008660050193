<template>
  <form action="" method="GET" @submit.prevent="search">
    <div class="search-bar">
      <label v-if="lang=='en'">
        Job title, keywords or company
      </label>
      <label v-else-if="lang=='fr'">
        Titre du poste, nom de l’employeur ou description de poste
      </label>
      <input type="search" v-model="searchTerm" />
      <button type="submit" class="button" v-if="lang=='en'">Search</button>
      <button type="submit" class="button" v-else-if="lang=='fr'">Recherche</button>
    </div>

    <div class="filters">
      <div class="filter">
        <label class="sr-only" v-if="lang=='en'">
          Date Posted
        </label>
        <label class="sr-only" v-else-if="lang=='fr'">
          Date de publication
        </label>
        <select name="filter_date" id="filter-date" class="dropdown-list" @change.prevent="filter('date', $event)" v-model="activeFilter.date">
          <option value="" v-if="lang=='en'">Date Posted</option>
          <option value="" v-else-if="lang=='fr'">Date de publication</option>
          <option v-for="option, value in dateOptions" v-bind:key="value" :value="value">{{ option[lang] }}</option>
        </select>
      </div>

      <div class="filter">
        <label class="sr-only" v-if="lang=='en'">
          Company
        </label>
        <label class="sr-only" v-else-if="lang=='fr'">
          Entreprise
        </label>
        <select name="filter_company" id="filter-company" class="dropdown-list" @change.prevent="filter('company', $event)" v-model="activeFilter.company">
          <option value="" v-if="lang=='en'">Company</option>
          <option value="" v-else-if="lang=='fr'">Entreprise</option>
          <option v-for="option, index in companies" v-bind:key="index" :value="option">{{ option }}</option>
        </select>
      </div>

      <div class="filter">
        <label class="sr-only" v-if="lang=='en'">
          Job Language
        </label>
        <label class="sr-only" v-else-if="lang=='fr'">
          Langue du poste
        </label>
        <select name="filter_language" id="filter-language" class="dropdown-list" @change.prevent="filter('language', $event)" v-model="activeFilter.language">
          <option value="" v-if="lang=='en'">Job Language</option>
          <option value="" v-else-if="lang=='fr'">Langue du poste</option>
          <option v-for="option, value in languageOptions" v-bind:key="value" :value="value">{{ option[lang] }}</option>
        </select>
      </div>

      <div class="sort">
        <a href="#" @click.prevent="resetFilters" v-if="filtered && lang=='en'">
          Reset Filters
        </a>
        <a href="#" @click.prevent="resetFilters" v-else-if="filtered && lang=='fr'">
          Réinitialiser les filtres
        </a>

        <span v-if="searching.length > 0 && searching">
          <template v-if="lang == 'en'">
            Sort by:
          </template>
          <template v-else-if="lang == 'fr'">
            Trier par :
          </template>

          <a href="#" @click.prevent="sortBy('default')" class="sort-option" :class="{'active': sort == 'default'}">
            <template v-if="lang == 'en'">
              Date Posted
            </template>
            <template v-else-if="lang == 'fr'">
              Date de publication
            </template>
          </a>

          <a href="#" @click.prevent="sortBy('relevance')" class="sort-option" :class="{'active': sort == 'relevance'}">
            <template v-if="lang == 'en'">
              Relevance
            </template>
            <template v-else-if="lang == 'fr'">
              Pertinence
            </template>
          </a>
        </span>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: 'SearchFilters',
  props: {
    companies: Array,
    lang: String
  },
  mounted() {
    this.resetFilters();
  },
  data() {
    return {
      filtered: false,
      searching: false,
      sort: 'default',
      activeFilter: {
        date: '',
        company: '',
        language: ''
      },
      searchTerm: '',
      languageOptions: {
        'en': {
          'en': 'English',
          'fr': 'Anglais'
        },
        'fr': {
          'en': 'French',
          'fr': 'Français'
        }
      },
      dateOptions: {
        '24': {
          'en': 'Last 24 hours',
          'fr': '24 dernières heures'
        },
        '72': {
          'en': 'Last 3 days',
          'fr': '3 derniers jours'
        },
        '168': {
          'en': 'Last 7 days',
          'fr': '7 derniers jours'
        },
        '336': {
          'en': 'Last 14 days',
          'fr': '14 derniers jours'
        }
      }
    }
  },
  methods: {
    sortBy(value) {
      this.sort = value;
      this.$emit('sort', this.sort);
    },
    search() {
      if (this.searchTerm.length > 0) {
        this.searching = this.searchTerm;
      } else {
        this.searching = false;
      }
      this.$emit('filter', 'keyword', this.searchTerm);
    },
    filter(filterParam, event) {
      this.filtered = true;
      this.$emit('filter', filterParam, event.srcElement.value);
    },
    resetFilters() {
      this.filtered = false;
      this.activeFilter = {
        date: '',
        company: '',
        language: ''
      };
      this.$emit('filter', 'clear');
    }
  }
}
</script>

<style>
  .search-bar {
    position: relative;
    display: block;
    margin: 20px 0;
    font-size: 17px;
  }

  .search-bar label {
    padding-left: 2em;
    color: #9e9e9e;
  }

  .search-bar input[type="search"] {
    display: block;
    width: 100%;
    margin: .5em 0 0;
    padding: .85em 2em;
    border-radius: 2em;
    border: solid 1px #c5c5c5;
    font-size: 17px;
    line-height: 1;
  }

  .search-bar .button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;
  }

  .filters {
    margin: 20px -15px;
  }

  .filters::after {
    display: table;
    clear: both;
    content: '';
  }

  .filter {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
    box-sizing: border-box;
  }

  .dropdown-list {
    appearance: none;
    display: block;
    margin: .5em 0 0;
    padding: .85em 3.62em .85em 2.62em;
    width: 100%;
    border-radius: 2em;
    border: solid 1px #c5c5c5;
    font-size: 13px;
    line-height: 1.2;
    font-weight: bold;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29 7' width='29' height='7'%3E%3Cpath style='fill:%23231f20' d='m1 0.1l5.3 5.1 5.3-5.1 0.9 0.9-6.2 6-6.2-6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right;
    overflow: hidden;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-list:focus {
    background-color: #e5e5e5;
  }

  .sort {
    clear: both;
    padding-top: 20px;
    text-align: right;
  }

  .sort a {
    color: inherit;
    display: inline-block;
    margin: 0 .5em;
  }

  .sort a.active {
    color: #002662;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    .filter {
      width: 100%
    }
  }

</style>
