<template>
  <article>
    <h1> <strong>{{ jobTitle }}</strong> - {{ employerName }}</h1>
    <p>{{ jobDescription }}</p>
    <span class="date" :title="humanDate(published)">{{ relativeDate(published) }}</span>
    <a class="button" target="_blank" :href="jobDetailsURL" v-if="lang == 'en'">Learn More</a>
    <a class="button" target="_blank" :href="jobDetailsURL" v-else-if="lang == 'fr'">En savoir plus</a>
  </article>
</template>

<script>
export default {
  name: 'JobPost',
  props: {
    job: Object,
    lang: String
  },
  inject: ['moment'],
  created() {
    if (this.lang == 'fr') {
      this.moment.updateLocale('fr', {
        relativeTime : {
          past: "Il y a %s"
        }
      });
    }
  },
  computed: {
    jobTitle() {
      return this.job.jobTitle._cdata;
    },
    employerName() {
      return this.job.employerName._cdata;
    },
    jobDescription() {
      return this.truncateString(this.job.jobDescription._cdata, 100, '...')
    },
    jobDetailsURL() {
      return this.job.jobDetailsURL._cdata;
    },
    published() {
      return this.job.pubDate._text;
    }
  },
  methods: {
    relativeDate(timestamp) {
      const published = this.moment(timestamp, 'x').set('hour', 12);
      if (this.moment().diff(published, 'hours') < 12) {
        return (this.lang == 'en') ? 'Posted today' : 'Publié aujourd\'hui'
      }
      return published.fromNow()
    },
    humanDate(timestamp) {
      return this.moment(timestamp, 'x').set('hour', 12).format('LL')
    },
    truncateString(str, len, append) {
      var newLength;
      append = append || "";

      if (append.length > 0) {
        append = " "+append;
      }

      if (str.indexOf(' ')+append.length > len) {
        return str;
      }

      str.length+append.length > len ? newLength = len-append.length : newLength = str.length;

      var tempString = str.substring(0, newLength);
      tempString = tempString.replace(/\s+\S*$/, "");


      if (append.length > 0) {
        tempString = tempString + append;
      }

      return tempString;
    }
  }
}
</script>

<style scoped>

  h1 {
    margin: 0;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.4;
  }

  article {
    position: relative;
    padding: 26px;
    margin-bottom: 16px;
    border: solid 1px #c5c5c5;
    border-radius: 3px;
    background: #fff;
  }

  p {
    margin-bottom: 0;
    line-height: 1.4;
  }

  .date {
    position: absolute;
    right: 26px;
    bottom: 26px;
    font-size: 14px;
    font-weight: bold;
    cursor: default;
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 20px;
    }

    article {
      font-size: 14px;
    }

    .date {
      position: static;
      display: block;
      margin-top: 1em;
    }
  }

</style>
