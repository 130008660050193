<template>
  <div class="pagination" v-if="jobCount > postsPerPage">
    <template v-if="lang == 'en'">
      <button @click.prevent="$emit('changePage', pageNumber - 1)" v-if="pageNumber > 1" class="prev">Prev</button>
    </template>
    <template v-else-if="lang == 'fr'">
      <button @click.prevent="$emit('changePage', pageNumber - 1)" v-if="pageNumber > 1" class="prev">Précédente</button>
    </template>
    <div class="page-buttons">
      <template v-for="page, i in pages" :key="page.number">
        <button :disabled="page.isDisabled" @click="$emit('changePage', page.number)" v-if="!page.hidden" class="number">
          {{page.number}}
        </button>
        <template v-else-if="page.hidden && i > 0 && !pages[i-1].hidden">
          ...
        </template>
      </template>
    </div>
    <template v-if="lang == 'en'">
      <button @click.prevent="$emit('changePage', pageNumber + 1)" v-if="pageNumber < pageMax" class="next">Next</button>
    </template>
    <template v-else-if="lang == 'fr'">
      <button @click.prevent="$emit('changePage', pageNumber + 1)" v-if="pageNumber < pageMax" class="next">Suivante</button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PageSwitcher',
  props: {
    pageNumber: Number,
    jobCount: Number,
    postsPerPage: Number,
    lang: String
  },
  computed: {
    pageMax() {
      return Math.ceil(this.jobCount / this.postsPerPage);
    },
    pages() {
      const range = [];

      for(let i = 1; i <= this.pageMax; i++) {
        let hidden = true;

        if (this.pageNumber <= 2 && i <= 3) {
          hidden = false;
        } else if (this.pageNumber > this.pageMax - 2 && i > this.pageMax - 3) {
          hidden = false;
        } else if (i >= this.pageNumber - 1 && i <= this.pageNumber + 1) {
          hidden = false;
        }

        if (i == 1 || i == this.pageMax || i == this.pageNumber) {
          hidden = false;
        }

        range.push({
          number: i,
          isDisabled: i === this.pageNumber,
          hidden
        })
      }
      return range;
    }
  }
}
</script>

<style scoped>
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px auto;
  }

  .page-buttons {
    display: inline-block;
    margin: auto;
  }

  button {
    display: inline-block;
    margin: 5px 0;
    padding: 1em 1.25em;
    height: 2.5em;
    background: #fff;
    border-radius: 1.5em;
    border: solid 2px #c5c5c5;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #002662;
    line-height: 0;
    cursor: pointer;
    max-width: 150px;
  }

  button:disabled {
    background: #c5c5c5;
  }

  .number {
    margin: 5px;
    padding: 1em .5em;
    width: 2.5em;
    line-height: 0;
    text-align: center;
  }

  .prev {
    margin-right: 5px;
    padding-left: 30px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 13' width='20' height='13'%3E%3Cpath style='fill:%23002561' d='m19.9 1l-5.3 5.5 5.3 5.5-0.9 0.9-6.3-6.4 6.3-6.5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center left;
  }

  .next {
    margin-left: 5px;
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 13' width='20' height='13'%3E%3Cpath style='fill:%23002561' d='m0.1 12l5.3-5.5-5.3-5.5 0.9-0.9 6.3 6.4-6.3 6.5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right;
  }

  @media (max-width: 740px) {
    .pagination {
      flex-direction: column;
      align-items: center;
    }

    .prev {
      margin-right: 0;
    }

    .next {
      margin-left: 0;
    }
  }

</style>
