<template>
  <AppHome :language="language" />
</template>

<script>
import AppHome from './views/AppHome.vue';
export default {
  name: 'App',
  components: {
    AppHome
  },
  data() {
    return {
      language: ''
    }
  },
  mounted() {
    const langAttribute = this.$el.parentElement.getAttribute('lang');
    if (langAttribute) {
      this.language = langAttribute;
    }
  }
}
</script>
